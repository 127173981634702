export default {
  methods: {
    generateDocumentDescription (document, config = {}) {
      /* configDefault: {
        hasRoute: false,
        priority: 'type'
      } */
      if (config.hasRoute) return {
        value: this.getDocumentDescription(document.type, document.reference, document.reference_type, config.priority),
        route: document.reference_type ? this.getDocumentReferenceRoute(document.reference_type, document.reference) : null,
        target: '_blank'
      }
      return this.getDocumentDescription(document.type, document.reference, document.reference_type)
    },
    getDocumentDescription (type, reference, reference_type, priority) {
      switch (type) {
      case 'beginning_inventory':
        return this.$t('documentDescriptions.beginningInventory.description')

      case 'receive':
        if (reference && reference_type === 'Receive') {
          return this.$t('documentDescriptions.receive.description', {id: reference.id})
        } else {
          return this.$t('documentDescriptions.clubReceive.description')
        }

      case 'payment':
        if (reference && reference_type === 'Payment') {
          return this.$t('documentDescriptions.payment.description', {id: reference.id})
        } else {
          return ''
        }

      case 'cost':
        if (reference_type === 'Cost') {
          if (reference.description || reference.note) {
            return this.$t('documentDescriptions.cost.dynamicDescription', {description: reference.description})
          } else {
            return this.$t('documentDescriptions.cost.description', {id: reference.id})
          }
        } else {
          return ''
        }

      case 'internal_fund_transfer':
        if (reference) {
          if (reference.type === 2) {
            return this.$t('documentDescriptions.receivePaymentGateway.description', {payee: this.getPayerName(reference.payee.type, reference.payee), payer: this.getPayerName(reference.payer.type, reference.payer)})
          } else if (reference.type === 1) {
            return this.$t('documentDescriptions.internalFundTransfer.description', {payee: this.getPayerName(reference.payee.type, reference.payee), payer: this.getPayerName(reference.payer.type, reference.payer)})
          }
        } else {
          return ''
        }
        break

      case 'sale':
        if (reference_type === 'Invoice') {
          return this.$t('documentDescriptions.sale.description', {id: reference.id})
        } else {
          return ''
        }

      case 'return_sale':
        if (reference_type === 'Invoice') {
          return this.$t('documentDescriptions.returnSale.description', {id: reference.id})
        } else {
          return ''
        }

      case 'purchase':
        if (reference_type === 'Invoice') {
          return this.$t('documentDescriptions.purchase.description', {id: reference.id})
        } else {
          return ''
        }

      case 'return_purchase':
        if (reference_type === 'Invoice') {
          return this.$t('documentDescriptions.returnPurchase.description', {id: reference.id})
        } else {
          return ''
        }

      case 'warehouse_receipt':
        if (reference) {
          if (reference_type === 'Invoice') {
            if (priority === 'reference_type') {
              const mapper = {
                '1': 'sale',
                '2': 'purchase',
                '3': 'returnSale',
                '4': 'returnPurchase'
              }
              return this.$t(`documentDescriptions.${mapper[reference.type]}.description`, {
                id: reference.id
              })
            } else return this.$t('documentDescriptions.warehouse_receipt.on_way_description', {
              invoice_id: reference.id,
              invoice_type: this.$t(`invoice_types.${reference.type}`)
            })
          }
          else if (reference_type === 'StoreroomReceipt') {
            return this.$t('documentDescriptions.warehouse_receipt.description', {
              receipt_id: reference.id
            })
          }
        } else {
          return ''
        }
        break

      case 'warehouse_requisition':
        if (reference) {
          if (reference_type === 'Invoice') {
            if (priority === 'reference_type') {
              const mapper = {
                '1': 'sale',
                '2': 'purchase',
                '3': 'returnSale',
                '4': 'returnPurchase'
              }
              return this.$t(`documentDescriptions.${mapper[reference.type]}.description`, {
                id: reference.id
              })
            } else return this.$t('documentDescriptions.warehouse_requisition.waiting_to_send_description', {
              invoice_id: reference.id,
              invoice_type: this.$t(`invoice_types.${reference.type}`)
            })
          } else if (reference_type === 'StoreroomReceipt') {
            return this.$t('documentDescriptions.warehouse_requisition.description', {
              receipt_id: reference.id
            })
          }
        } else {
          return ''
        }
        break

      case 'inventory_transfer':
        if (reference) {
          return this.$t('documentDescriptions.inventory_transfer.description', {
            receipt_id: reference.id
          })
        } else {
          return ''
        }

      default:
        return {}
      }
    },
    getPayerName (type, info) {
      switch (type) {
      case 'user':
        return info.name

      case 'booking_app':
        return this.$t('documentDescriptions.bookingApp.title')

      case 'cash':
        return this.$t('treasury.cashBoxes.cashBox.dynamicTitle', {cashBox: info.name})

      case 'bank':
        return this.$t('treasury.banks.bank.dynamicTitle', {bank: info.name})

      case 'payment_gateway':
        return this.$t('treasury.paymentGateways.paymentGateway.dynamicTitle', {paymentGateway: info.name})
      }
    },
    getDocumentReferenceRoute (type, reference) {
      const mapper = {
        '1': 'saleInvoice',
        '2': 'purchaseInvoice',
        '3': 'returnSaleInvoice',
        '4': 'returnPurchaseInvoice'
      }
      switch (type) {
        case 'Invoice':
          return {name: mapper[reference.type], params: {id: reference.id}}

        case 'OpeningInventory':
          return {name: 'openingBalance'}

        case 'Receive':
          return {name: 'treasuryReceiveDocument', params: {id: reference.id}}

        case 'Payment':
          return {name: 'treasuryPaymentDocument', params: {id: reference.id}}

        case 'Cost':
          return {name: 'treasuryCostDocument', params: {id: reference.id}}

        case 'InternalFund':
          return {name: 'treasuryInternalFundsTransferDocument', params: {id: reference.id}}

        case 'StoreroomReceipt':
          return {name: 'storeReceipt', params: {id: reference.id}}

        default:
          return {}
      }
    }
  }
}
